body {
  background-color: #f9fbfd;
  letter-spacing: 0.05em;
}

a {
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

[data-toggle="dropdown"] {
  cursor: pointer;
}

:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}