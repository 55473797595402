.notify {
  display: flex;
  color: #4d585f !important;
  background-color: #fafafa;
  transition: background-color .2s ease-in-out;

  &--read {
    background-color: #fffffa;
  }

  &:hover {
    background-color: #fafaf0;
  }

  &__icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    background: $blue;
    color: #fff;
    border-radius: 50%;
  }

  &__content {
    padding: 10px 15px 10px 0;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
  }

  &__time {
    color: #7d8c95;
    font-size: 11px;
    white-space: nowrap;
  }
}