.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  margin-top: 60px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 4px 4px 10px rgba(69,65,78,.06);


  &__section {
    padding: 0 20px;
    font-size: 12px;
    color: #727275;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  &__list, &__list ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li a {
      display: flex;
      position: relative;
      align-items: center;
      color: #8d9498;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 10px;
      border-radius: 5px;
      margin: 0 15px 3px 15px;
      transition: all .2s ease-in-out;

      &.active {
        background-color: #1572e8 !important;
        color: #fff;
        box-shadow: 4px 4px 10px 0 rgba(0,0,0,.1),4px 4px 15px -5px rgba(21,114,232,.4)!important;
      }

      &:hover {
        background-color: rgba(199,199,199,.2);
      }

      i {
        margin-right: 15px;
        width: 25px;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
      }
    }

    li.has-children {
      &.active > a {
        background-color: rgba(199,199,199,.2);

        &:after {
          transform: rotate(180deg);
        }
      }

      ul {
        display: none;

        li a {
          margin: 0 15px 3px 15px;

          &:before {
            content: '';
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 4px;
            margin: 0 16px 0 20px;
          }
        }
      }

      & > a:after {
        content: '';
        position: absolute;
        top: 12px;
        right: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        transition: all .25s ease-in-out;
      }
    }
  }

  &__list ul li a {
    margin-left: 25px;
    opacity: .85;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.user {
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;

  &__info {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #777;
    margin-bottom: 20px;

    img {
      width: 2.5rem;
      height: 2.5rem;
      object-fit: contain;
    }
  }

  &__name {
    display: block;
    font-size: 14px;
  }

  &__role {
    color: #555;
    font-weight: 600;
    font-size: 12px;
    margin-top: 3px;
  }

  &__menu ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li a {
      display: block;
      padding-bottom: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #777;
      transition: all .2s ease-in-out;

      &:hover {
        color: #0d0d0d;
      }
    }
  }
}