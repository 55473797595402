@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, a, .td-name, td, button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif;
}

h2, .h2 {
  font-size: 1.35rem;
}

h5, .h5 {
  font-size: .9125rem;
}

label, .col-form-label {
  color: #495057 !important;
  font-size: 14px !important;
  font-weight: 600;
}

//label.required:after {
//  content: "* ";
//  color: #FF2000;
//}

.custom-control-label {
  line-height: 1.7;
}

.auth__title {
  font-size: 19px;
  font-weight: 600;
  color: #575962;
  margin: 2rem 0 3rem 0;
  text-align: center;
}