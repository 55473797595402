.nav-tabs {
  display: flex;
  flex-direction: row;
  border-bottom: none;

  .nav-item {
    margin: 0 5px;

    .nav-link {
      text-align: center;
      border: none;
      border-radius: 5px;
      background: rgba(222, 222, 222, 0.4);
      color: #585c5d !important;

      &.active {
        background: #1572e8;
        color: #fff !important;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}