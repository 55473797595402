.block {
  //min-height: calc(100% - 1.5rem);
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 2px 6px 15px 0 rgba(69,65,78,.1);
  -moz-box-shadow: 2px 6px 15px 0 rgba(69,65,78,.1);
  box-shadow: 2px 6px 15px 0 rgba(69,65,78,.1);

  &.hidden {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #ebecec;
  }

  &__title {
    color: #575962;
    font-size: 20px;
    line-height: 1.6;
  }

  &__body {
    padding: 1rem 1.25rem;
    font-size: 14px;
    color: #8d9498;
  }

  &--scrollable &__body {
    max-height: 500px;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    border-top: 1px solid #ebecec;
  }
}