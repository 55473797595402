.table {
  margin-bottom: 0;

  thead th {
    border-bottom-width: 2px;
    font-weight: 600;
  }

  td, th {
    font-size: 14px;
    border-top-width: 0;
    border-bottom: 1px solid;
    border-color: #ebedf2;
    padding: 0 25px;
    height: 60px;
    vertical-align: middle!important;
  }

  tr:last-child td, tr:last-child th {
    border-bottom: none !important;
  }
}
.table--datatable {
  border-collapse: separate !important;

  td, th {
    border-bottom: none !important;
  }

  thead th {
    position: relative !important;
    cursor: pointer;
  }

  thead th:not(:last-child):after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 0.5em;
    color: #b3b3b3;
  }

  thead th.active:after {
    color: #000;
  }
}