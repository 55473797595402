$fa-font-path: "../webfonts";

$blue:    #1572E8;
$purple:  #6861CE !default;
$red:     #F25961 !default;
$yellow:  #FFAD46 !default;
$green:   #31CE36 !default;
$cyan:    #48ABF7 !default;

$primary:       $blue !default;
$secondary:     $purple !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         #ebedf2;

$theme-colors: (
  "default": #ebedf2,
);

$input-border-color: #ebedf2;
$btn-padding-x: 1rem;
$btn-padding-y: .6rem;
$input-padding-x: 1rem;
$input-padding-y: .6rem;