.header {
  position: fixed;
  height: 60px;
  width: 100%;
  z-index: 1001;
  box-shadow: 0 0 5px rgba(18,23,39,.5);

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    float: left;
    background-color: #1572e8;
    width: 250px;
    padding: 0 20px;
    height: 100%;
    color: #fff;

    img {
      max-height: 40px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    float: right;
    padding: 0 20px;
    background-color: #1269db;
    width: calc(100% - 250px);
    height: 100%;

    .input-group {
      border: 0;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.14) !important;
      box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.07);
      transition: all .4s;

      .input-group-text {
        color: #fff;
        border: 0;
        background: transparent;
        padding: .375rem 1rem;
      }

      input {
        color: #fff !important;
        border: 0;
        background: transparent !important;
        font-size: 14px;
        padding: 0.75em 1em;
        padding-left: 0;
        min-width: 200px;
        max-width: 100%;
      }

      input::-webkit-input-placeholder { color: #fff; }
      input:-ms-input-placeholder { color: #fff; }
      input::placeholder { color: #fff; }
    }

    .header__icons {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        display: inline-block;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color .2s ease-in-out;

        &:hover {
          background-color: rgba(31, 30, 30, 0.12);
        }

        .icon__overlay {
          position: absolute;
          background-color: #31CE36;
          text-align: center;
          border-radius: 10px;
          min-width: 17px;
          height: 17px;
          font-size: 10px;
          color: #ffffff;
          font-weight: 300;
          line-height: 17px;
          top: 3px;
          right: 3px;
          letter-spacing: -1px;
        }
      }
    }

    .dropdown-menu {
      border: 0px;
      border-radius: 3px;
      box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;

      .dropdown-title {
        color: #444444;
        font-size: 14px;
        font-weight: 600;
        padding: 12px 30px;
        text-align: center;
        white-space: nowrap;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.page-header {
  background: #1269db;
  background: linear-gradient(-45deg, #06418E, #1572E8) !important;
  margin: -1.5rem;
  padding: 3rem 1.5rem;
  color: #fff;

  h2 {
    font-weight: 600;
  }

  h5 {
    opacity: 0.7;
  }
}