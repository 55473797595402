@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap');

.pt__curtain {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    pointer-events: none;
    z-index: 9999997;
}

.pt__tooltip {
    position: absolute;
    will-change: transform;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: #2c8eeb;
    color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    width: auto;
    min-width: 500px;
    max-width: 700px;
    max-height: 100%;
    z-index: 9999999;
}

.pt__tooltip:before {
    content: '';
    position: absolute;
    background-color: #2c8eeb;
    z-index: 10000010;
}

.pt__tooltip[data-placement="right"]:before,
.pt__tooltip[data-placement="left"]:before {
    width: 16px;
    height: 32px;
    top: 50%;
    transform: translateY(-50%);
}

.pt__tooltip[data-placement="right"]:before {
    right: 100%;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
}

.pt__tooltip[data-placement="left"]:before {
    left: 100%;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.pt__tooltip[data-placement="top"]:before,
.pt__tooltip[data-placement="bottom"]:before{
    width: 32px;
    height: 16px;
    left: 50%;
    transform: translateX(-50%);
}

.pt__tooltip[data-placement="top"]:before {
    top: 100%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background-color: #1c84e5;
}

.pt__tooltip[data-placement="bottom"]:before {
    bottom: 100%;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.pt__close {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    cursor: pointer;
    color: #6bb7ff;
    font-size: 20px;
    font-weight: 800;
    transition: color .2s ease-in-out;
}

.pt__close:hover {
    color: #fff;
}

.pt__header {
    padding: 1.5rem 3rem 0 1.5rem;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    z-index: -1;
}

.pt__content {
    margin: 1.5rem;
    padding-right: 5px;
    color: #bbdefe;
    font-size: 14px;
    font-weight: 400;
    max-height: 230px;
    overflow-y: auto;
}

.pt__content a {
    color: #fff;
    font-weight: 600;
}

.pt__content::-webkit-scrollbar {
    width: 8px;
}

.pt__content::-webkit-scrollbar-track {
    background-color: #69b0f1;
    border-radius: 50px;
}

.pt__content::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 50px;
}

.pt__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-radius: 0 0 5px 5px;
    background-color: #1c84e5;
}

.pt__previous, .pt__next {
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 800;
    min-width: 120px;
    text-align: center;
    padding: .3rem;
    border-radius: 50px;
    user-select: none;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.pt__previous {
    background-color: #54a8f7;
    color: #fff;
}

.pt__next {
    background-color: #fff;
    color: #2c8eeb;
}

.pt__indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.pt__indicators div {
    display: inline-block;
    background-color: #fff;
    color: #2c8eeb;
    font-size: 11px;
    font-weight: 700;
    padding: .3rem 1rem;
    border-radius: 50px;
}

.pt__indicators span {
    display: block;
    background-color: #8ec2f2;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    margin: 0 .2rem;
    cursor: pointer;
}

.pt__indicators span.active {
    cursor: default;
    border: 4px solid #fff;
    background-color: #22cc00;
}

@media (max-width: 1600px) {
    .pt__tooltip { width: 500px; max-width: 100%; min-width: auto; }
}

@media (max-width: 1200px) {
    .pt__tooltip { width: 400px; }
}

@media (max-width: 992px) {
    .pt__tooltip { width: 380px; }
}

@media (max-width: 768px) {
    .pt__tooltip { width: 350px; }
    .pt__indicators { display: none }
}