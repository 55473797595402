.breadcrumbs {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid #efefef;

  li {
    display: inline-block;
    color: #575962;
    font-size: 13px;
  }

  li:not(:last-child):after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    padding: 0 10px;
  }

  li i {
    font-size: 16px;
  }

  li a {
    color: inherit;
  }
}