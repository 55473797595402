.form-control {
  font-size: 14px;
  height: inherit !important;
}

.form-control:focus {
  border-color: #3e93ff;
}

.form-float {
  position: relative;

  input {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding: calc(.75rem + 10px) 0 .75rem 0;
    font-size: 14px;
    background: 0 0;
    height: inherit;
    outline: none !important;
    box-shadow: none !important;
  }

  input:focus {
    border-color: #3e93ff;
  }

  input + label {
    position: absolute;
    padding: .375rem 0 .75rem;
    transition: all .2s;
    opacity: .8;
    margin-bottom: 0!important;
    font-size: 14px!important;
    font-weight: 400;
    top: 12px;
  }

  input:focus + label, input.filled + label {
    font-size: 85%!important;
    transform: translate3d(0,-10px,0);
    top: 0;
    opacity: 1;
    padding: .375rem 0 .75rem;
    font-weight: 600;
  }
}

.bootstrap-select > .dropdown-toggle {
  color: #495057;
  border: 1px solid #ebedf2 !important;
}