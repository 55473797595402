.main {
  position: relative;
  width: calc(100% - 250px);
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 1.5rem 1.5rem 0 1.5rem;
  float: right;

  &__title {
    font-size: 23px;
    font-weight: 600;
    color: #444;
    line-height: 30px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}