.btn {
  font-size: 14px;
  opacity: 1;
  border-radius: 3px;
}

.btn-sm {
  font-size: 11px;
  padding: 7px 13px;
}

.btn-round {
  border-radius: 100px !important;
}

.btn-load {
  margin-top: .5rem;
  width: 100%;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #797979;
}

.bootstrap-select {
  .btn-light {
    background: transparent;
  }

  select.is-invalid + .btn {
    border-color: #F25961 !important;
  }
}