.panel-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background-color: #f2f2f2;
    border-radius: 5px;
    margin: 5px 0;
    padding: 12px;
    cursor: pointer;
    transition: all .1s ease-in-out;

    &:not(.active):hover {
      background-color: #6861CE;
      color: #fff;

      .panel-list__text, .panel-list__text small {
        color: #fff;
      }
    }

    &.parent.active .fa-caret-down {
      transform: rotate(180deg);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: #6861CE;
    font-weight: 600;
    transition: color .1s ease-in-out;

    small {
      color: #8d9498;
      transition: color .1s ease-in-out;
    }
  }

  &__icons {
    font-size: 14px;

    i {
      margin-left: .3em;
    }
  }

  ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 12px 0 -5px 0px;

    li {
      background-color: #e0e0e0;
    }
  }
}